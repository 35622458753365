import { Routes } from '@angular/router';
import { LoginDialogRouteComponent } from 'src/app/components/auth/login-dialog/login-dialog.component';
import { GameOperatorCategoryComponent } from 'src/app/components/front-page/operators-category/operator.component';
import { loginTrx, providerTrx } from 'src/app/router-translation.labels';
import { MaximumPageResolver } from '../../resolvers/restore-max-page.resolver';

const loginRoute = {
  path: loginTrx,
  component: LoginDialogRouteComponent
};

export const MOBILE_OPERATORS_MODULE_NOT_TRANSLATED: Routes = [
  {
    path: providerTrx,
    component: GameOperatorCategoryComponent,
    data: { isAll: true, isliveCasino: true },
    resolve: { maximize: MaximumPageResolver },
    children: [
      loginRoute
    ]
  }
];
